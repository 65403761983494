import * as React from "react";
import { SEO } from "../components/seo";
import { Layout } from "../components/layout";
import { FullScreenImage } from "../components/full-screen-image";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    {
      aboutImage: file(
        relativePath: { eq: "andrea-about.jpeg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      aboutImageMobile: file(
        relativePath: { eq: "andrea-about-mobile.jpeg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      reelImage: file(
        relativePath: { eq: "andrea-reel.jpeg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      reelImageMobile: file(
        relativePath: { eq: "andrea-reel-mobile.jpeg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      imagesImage: file(
        relativePath: { eq: "andrea-images.jpeg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      imagesImageMobile: file(
        relativePath: { eq: "andrea-images-mobile.jpeg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      pressImage: file(
        relativePath: { eq: "andrea-press.jpeg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      pressImageMobile: file(
        relativePath: { eq: "andrea-press-mobile.jpeg" }
        sourceInstanceName: { eq: "images" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 2000
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  const aboutImage = getImage(data.aboutImage);
  const aboutImageMobile = getImage(data.aboutImageMobile);
  const reelImage = getImage(data.reelImage);
  const reelImageMobile = getImage(data.reelImageMobile);
  const imagesImage = getImage(data.imagesImage);
  const imagesImageMobile = getImage(data.imagesImageMobile);
  const pressImage = getImage(data.pressImage);
  const pressImageMobile = getImage(data.pressImageMobile);

  return (
    <Layout>
      <FullScreenImage
        id={"id"}
        image={aboutImage}
        imageAlt={"Andrea Lopez About Image"}
        mainText={"About"}
        subText={"Actress, Writer, Director"}
        url={"about"}
      />
      <FullScreenImage
        id={"id"}
        image={aboutImageMobile}
        imageAlt={"Andrea Lopez About Image"}
        isMobile
        mainText={"About"}
        subText={"Actress, Writer, Director"}
        url={"about"}
      />
      <FullScreenImage
        id={"id"}
        image={reelImage}
        imageAlt={"Andrea Lopez Reel Image"}
        mainText={"Reel"}
        subText={"Showcasing Latest Work"}
        url={"reel"}
      />
      <FullScreenImage
        id={"id"}
        image={reelImageMobile}
        imageAlt={"Andrea Lopez Reel Image"}
        isMobile
        mainText={"Reel"}
        subText={"Showcasing Latest Work"}
        url={"reel"}
      />
      <FullScreenImage
        id={"id"}
        image={imagesImage}
        imageAlt={"Andrea Lopez Images Image"}
        mainText={"Images"}
        subText={"Various Projects"}
        url={"images"}
      />
      <FullScreenImage
        id={"id"}
        image={imagesImageMobile}
        imageAlt={"Andrea Lopez Images Image"}
        isMobile
        mainText={"Images"}
        subText={"Various Projects"}
        url={"images"}
      />
      <FullScreenImage
        id={"id"}
        image={pressImage}
        imageAlt={"image-alt"}
        mainText={"Press"}
        subText={"Latest Press Clipings"}
        url={"press"}
      />
      <FullScreenImage
        id={"id"}
        image={pressImageMobile}
        imageAlt={"image-alt"}
        isMobile
        mainText={"Press"}
        subText={"Latest Press Clipings"}
        url={"press"}
      />
    </Layout>
  );
};

export default IndexPage;

export const Head = () => <SEO />;
