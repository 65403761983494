import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import "./full-screen-image.css";

const FullScreenImage = ({
  id,
  image,
  imageAlt,
  isMobile,
  mainText,
  subText,
  url,
}) => {
  return (
    <div
      className={isMobile ? "wrapper-mobile" : "wrapper"}
      id={id}
      image={image}
    >
      <div className={"background"}>
        <GatsbyImage
          image={image}
          alt={imageAlt}
          style={{
            flexGrow: 1,
            minwidth: "100%",
            minHeight: "100%",
          }}
        />
      </div>
      <div className={"wrapper-text"}>
        <div className={"bar-color"} />
        <Link className={"wrapper-link"} to={url}>
          <h1 className={"text-main"}>{mainText}</h1>
          <h4 className={"text-sub"}>{subText}</h4>
        </Link>
      </div>
    </div>
  );
};

export { FullScreenImage };
